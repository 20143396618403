export interface SupplierTab {
    name: string;
    href: string;
    icon?: any; // Need to add a supertype for HeroIcons
    slug?: string;
}

export const SUPPLIER_TYPE_ACTIVE_BASEPATHS = {
    programs: true,
    ["hotels-with-perks"]: true,
    ["hotels"]: true /*booking platform FF*/,
    ["packages"]: true,
    cruises: true,
    dmcs: true,
    flights: true,
    ["home-and-villas"]: true,
    ["tours-and-activities"]: true,
    transportation: true,
    insurance: true
};

export type SupplierTypeSlugs =
    | "hotel"
    | "all_inclusive"
    | "package"
    | "flight"
    | "cruise"
    | "dmc"
    | "home_villa"
    | "tour"
    | "ground_transportation"
    | null;

export type SupplierFilterLabelSlugs = "preferred";

export type InfoBlockTypeOptions = "text" | "text-links";

export type InfoBlock = {
    blockType: InfoBlockTypeOptions;
    title: string;
    titleIcon: string;
    content: string | InfoBlockTextLink[];
};
export interface InfoBlockTextLink {
    label: string;
    url: string;
}

export type GuideSupplierDB = {
    title: string;
    slug: string;
    data: InfoBlock[];
};

export enum CheckoutErrorSlugs {
    travelport_unknown_exception = "travelport_unknown_exception",
    travelport_price_mismatch = "travelport_price_mismatch",
    invalid = "invalid"
}

export type CheckoutErrorResponse = {
    detail: string;
    code: string;
    program_id?: string;
    client_loyalty_program_id?: string;
    slug?: string;
};

export enum BookingPlatformViewModes {
    LIST = "list",
    MAP = "map"
}

export type MapLongLat = [long: number, lat: number];
export const DEFAULT_MAP_ZOOM = 14;
export const MAP_ZOOM_FIND_SUPPLIER = 6;
export const DEFAULT_LNG_LAT = [-74.0042, 40.7174];
